<template>
  <button
    @click.stop="handleToggleActive"
    class="px-16 py-4 rounded space-x-2 transition-all duration-300 ease-out"
    :class="[active ? 'bg-white' : 'bg-blue-500']"
  >
    <span
      class="font-poppins font-semibold transition-all duration-300 ease-out"
      :class="[active ? 'text-blue-600' : 'text-white']"
      >{{ label }}</span
    >
    <span
      class="px-6 leading-8 inline-block rounded-2xl font-poppins font-semibold transition-all duration-300 ease-out"
      :class="[active ? 'bg-blue-600 text-white' : 'bg-white text-blue-500']"
      >{{ badge }}</span
    >
  </button>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  props: {
    label: {
      type: String,
      default: () => '',
    },
    badge: {
      type: Number,
      default: () => 0,
    },
    modelValue: {
      type: Boolean,
    },
  },
  emits: ['update'],
  setup(props, { emit }) {
    const active = ref(props.modelValue);

    watch(
      () => props.modelValue,
      (newValue) => {
        active.value = newValue;
      }
    );

    function handleToggleActive() {
      active.value = !active.value;
      emit('update', active.value);
    }

    return {
      active,
      handleToggleActive,
    };
  },
};
</script>
