<template>
  <Modal id="change-order" title="Add Change Order">
    <template #default="{ close }">
      <Form
        class="mt-4 space-y-6"
        :validationSchema="validationSchema"
        :initial-values="initialValues"
        @success="handleSuccess($event, close)"
        method="post"
        :action="`ukzs/change-order/${item.id}`"
      >
        <Field
          name="currentScheduledValue"
          placeholder="Previously Scheduled Value"
          label="Previously Scheduled Value"
          readonly
        />
        <Field
          name="scheduledValue"
          placeholder="Scheduled Value"
          label="Scheduled Value"
        />
        <Field name="date" label="PO Issue Date" type="date" />
        <div class="text-center">
          <Button class="mr-2">Submit</Button>
          <Button class="ml-2 w-1/3" variant="secondary" @click="close"
            >Cancel</Button
          >
        </div>
      </Form>
    </template>
  </Modal>
</template>

<script>
import { object, number, date } from 'yup';
import { useToast } from '@/helpers/composables';
import { ref, watch} from 'vue';

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    }
  },
  emit:['updateUkz'],
  setup(props, { emit }) {
    const toast = useToast();
    const initialValues= ref();

    watch(() => props.item, (first) => {
      initialValues.value = {
        currentScheduledValue : first.scheduledValue,
      };
    });

    const validationSchema = object().shape({
      scheduledValue: number().typeError('${path} should be a valid ${type}'),
      date: date().typeError('${path} should be a valid ${type}'),
    });

    function handleSuccess({ message }, close){
      emit('updateUkz', true);
      toast.show(message);
      close();
    }

    return {
      validationSchema,
      handleSuccess,
      initialValues
    };
  },
};
</script>
