<template>
  <Table
    v-if="data.length"
    class="table"
    :headers="headers"
    :columns="columns"
    :data="data"
    :headerClasses="'font-poppings font-semibold'"
    :columnClasses="'py-2'"
  >
    <template #appendColumn="{ row }">
      <td>
        <router-link :to="`/invoices/${row.id}`"
          ><right-arrow-icon class="w-4 h-4"
        /></router-link>
      </td>
    </template>
  </Table>
  <div v-else class="text-center font-poppings font-semibold">
    There are no invoices for this job.
  </div>
  <div class="text-right mt-6 space-x-4" v-if="user && user.roleId != userRoleID.CLIENT">
    <Button class="w-48" @click="createInvoice(ukz.id)">
      Add Invoice
      <plus-icon class="inline-block w-3 h-3 ml-12 -mt-1" />
    </Button>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Table from '@/components/tables/base/Table.vue';
import { useToast } from '@/helpers/composables';
import constant from '@/helpers/constant';

export default {
  components: {
    Table,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    ukz: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const toast = useToast();
    const { user } = store.state.user;
    const userRoleID = constant.USER_ROLE_ID;

    const headers = ref([
      {
        label: 'Invoice #',
        name: 'invoiceNumber',
      },
      {
        label:'Ukz Number',
        name:'ukzNumber'
      },
      {
        label: 'Description',
        name: 'description',
      },
      {
        label: 'Invoice Amount',
        name: 'invoiceAmount',
      },
      {
        label: 'PO Number',
        name: 'poNumber',
      },
      {
        label: 'Status',
        name: 'status',
      },
    ]);

    const columns = ref([
      {
        name: 'invoiceNumber',
        class: 'font-poppins font-semibold',
      },
      {
        name: 'ukzNumber',
        render: () => `${props.ukz.ukzNumber}`
      },
      {
        name: 'description',
      },
      {
        name: 'invoiceAmount',
        render: ({ row }) => {
          if(row.lineItems && row.lineItems[0].currentInvoice){
            var formatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            });
            return formatter.format(row.lineItems[0].currentInvoice);
          }
        }
      },
      {
        name:'poNumber',
        render: () => `${props.ukz.poNumber}`
      },
      {
        name: 'status',
        component: 'Badge',
        render: ({ row }) => {
            return row.status && row.status.title;
        },
        $props: ({ row }) => {
          const variants = constant.INVOICE_STATUS_COLOR_CODING;
          return {
            variant: variants[row.status && row.status.title],
          };
        },
      },
    ]);

    async function createInvoice(ukzId) {
      if (ukzId) {
        const formData = { ukzId };
        const { data, message } = await store.dispatch('post', {
          action: '/invoices',
          formData,
        });
        toast.show(message);
        router.push(`/invoices/${data.id}`);
      }
    }

    return {
      headers,
      columns,
      createInvoice,
      userRoleID,
      user,
    };
  },
};
</script>
