<template>
  <div
    :class="{ 'shadow rounded-bl-md rounded-br-md overflow-hidden': item.open }"
  >
    <header
      class="rounded-tl rounded-tr py-4 bg-blue-600 text-white border-gray-300 border-b px-6 flex justify-between items-center cursor-pointer"
      :class="{ 'rounded-bl-md rounded-br-md shadow': !item.open }"
      @click="handleToggle"
    >
      <section class="flex items-center space-x-8">
        <div>
          <h3 class="text-xl font-medium font-poppins">{{ item.jobNumber }}</h3>
        </div>
        <div class="space-x-4 flex">
          <TabButton
            label="Invoices"
            :badge="data && data.length"
            :modelValue="tab === 0"
            @update="handleChangeTab(0)"
          />
          <TabButton
            label="Change Orders"
            :badge="changeOrderData && changeOrderData.length"
            :modelValue="tab === 1"
            @update="handleChangeTab(1)"
          />
          <div class="px-4 py-2 rounded">
            <div class="space-x-2">
              <span class="font-poppins font-semibold text-white"
                >Scheduled Value:
              </span>
              <span class="font-poppins text-white">{{
                formatPrice(item.scheduledValue)
              }}</span>
            </div>
            <div class="space-x-2">
              <span class="font-poppins font-semibold text-white"
                >Billed to Date:
              </span>
              <span class="font-poppins text-white">{{
                formatPrice(item.totalBilled)
              }}</span>
            </div>
          </div>
        </div>
      </section>
      <button>
        <chevron-down-icon
          class="h-6 w-6 text-white stroke-1 transform rotate-0 transition-all ease-out duration-300"
          :class="{ 'transform rotate-180': item.open }"
        />
      </button>
    </header>
    <div v-show="item.open">
      <transition name="fade">
        <div
          class="px-8 pt-8 pb-6 bg-white border-gray-100 border-l border-b border-r"
        >
          <UkzInvoicesTable v-if="tab === 0" :data="data" :ukz="item" />
          <UkzChangeOrdersTable v-if="tab === 1" :data="changeOrderData" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import UkzInvoicesTable from '@/components/tables/UkzInvoicesTable.vue';
import UkzChangeOrdersTable from '@/components/tables/UkzChangeOrdersTable.vue';
import TabButton from '@/components/elements/TabButton.vue';
import { useState, useLocalDateTime } from '@/helpers/composables';
import { ref, onMounted, watch, computed } from 'vue';
import { useStore } from 'vuex';
import constant from '@/helpers/constant';

export default {
  components: {
    UkzInvoicesTable,
    UkzChangeOrdersTable,
    TabButton,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    updateChangeOrder: {
      type: Boolean,
      default: () => false,
    },
  },
  emits: ['setOpen'],
  setup(props, { emit }) {
    const [tab, setTab] = useState(0);
    const changeOrderData = ref([]);
    const store = useStore();
    const { user } = store.state.user;
    const userRoleID = constant.USER_ROLE_ID;
    const status = ref(constant.INVOICE_STATUS);

    function handleToggle() {
      emit('setOpen', props.item.ukzNumber);
    }

    function handleChangeTab(tab) {
      setTab(tab);
    }

    function formatPrice(value) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(value);
    }

    onMounted(() => {
      fetchChangeOrder();
    });
    const data = computed(() => {
      let val = [];
      if (user && user.roleId == userRoleID.CLIENT) {
        val = props.item.invoices.filter((el) => {
          if (
            el.status.phrase == status.value.INVOICE_ISSUED ||
            el.status.phrase == status.value.OFFLINE
          ) {
            return el;
          }
        });
      } else {
        val = props.item.invoices;
      }
      return val;
    });

    watch(
      () => props.updateChangeOrder,
      (val) => {
        if (val == true) {
          fetchChangeOrder();
        }
      }
    );

    watch(data, () => {
      fetchChangeOrder();
    });

    async function fetchChangeOrder() {
      const { data } = await store.dispatch('get', {
        action: `/ukzs/change-order/${props.item.id}`,
      });
      changeOrderData.value = data;
    }

    return {
      tab,
      handleToggle,
      handleChangeTab,
      useLocalDateTime,
      fetchChangeOrder,
      changeOrderData,
      user,
      userRoleID,
      data,
      status,
      formatPrice,
    };
  },
};
</script>
