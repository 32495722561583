<template>
  <div class="bg-gray-50 pt-16 h-full relative">
    <div class="px-4 overflow-x-hidden bg-gray-50">
      <section class="lg:px-16">
        <header class="flex justify-between items-center">
          <h1 class="text-4xl font-poppins font-normal">Invoices by Jobs</h1>
          <h2
            class="text-xl font-poppins font-normal uppercase"
            v-if="pagination"
          >
            Total # of Jobs: {{ pagination.total }}
          </h2>
        </header>
        <div class="mt-8 flex justify-end items-center space-x-8">
          <SearchDropDown
            class="w-1/4"
            placeholder="Filter by UKZ"
            display-key="ukzNumber"
            :items="allData"
            :search-keys="['ukzNumber']"
            name="ukzNumber"
            @select="handleFilter"
          />
          <SearchDropDown
            class="w-1/4"
            placeholder="Filter by Pfizer EPM"
            display-key="pfizerEpm"
            :items="getUniquePfizerEpm"
            :search-keys="['pfizerEpm']"
            name="pfizerEpm"
            @select="handleFilter"
          />
          <form @submit.prevent="submitSearch" class="w-1/4">
            <Field placeholder="Search" v-model="search">
              <template #append>
                <search-icon class="w-5 h-5 mt-1 text-gray-500" />
              </template>
            </Field>
          </form>
        </div>
      </section>
      <section class="mt-8 space-y-4 lg:px-16 flex-1 mb-32">
        <UkzCard
          v-for="(item, index) in data"
          :key="index"
          :item="item"
          :updateChangeOrder="updateChangeOrder"
          @setOpen="setOpen"
        />
      </section>
    </div>
    <section class="absolute bottom-0 w-full" v-if="pagination">
      <div
        class="fixed w-full bottom-0 flex justify-between items-center pr-72 pl-8 py-4 bg-gray-100 z-20"
      >
        <span class="font-poppins">
          Showing {{ pagination.from }} to {{ pagination.to }} of
          {{ pagination.total }}
        </span>
        <div class="flex">
          <button
            :disabled="pagination.currentPage == 1"
            class="rounded-l-md pagination-button"
            @click="handlePrev"
          >
            Prev
          </button>
          <button
            :disabled="pagination.currentPage == pagination.lastPage"
            class="rounded-r-md pagination-button"
            @click="handleNext"
          >
            Next
          </button>
        </div>
      </div>
    </section>
  </div>
  <ChangeOrderModal :item="seletedUkz"  @updateUkz="handleUpdate"/>
</template>

<script>
import { onMounted, ref, computed } from 'vue';
import SearchDropDown from '@/components/dropdowns/SearchDropdown.vue';
import UkzCard from '@/components/cards/UkzCard.vue';
import { useFetch } from '@/helpers/composables';
import ChangeOrderModal from '@/components/overlays/modals/ChangeOrderModal.vue';

export default {
  components: {
    SearchDropDown,
    UkzCard,
    ChangeOrderModal 
  },
  setup() {
    const search = ref([]);
    const { fetch, data, pagination, handleFilter, handleSearch } =
      useFetch('/ukzs');

    const { fetch: fetchAll, data: allData } = useFetch('/ukzs/all');

    const seletedUkz = ref({});
    const updateChangeOrder = ref(false);
    
    onMounted(() => {
      fetch();
      fetchAll();
    });

    const getUniquePfizerEpm = computed(() => {
      let uniqueArr = [];
      if(allData.value){
        allData.value.filter((value) => {
          const found = uniqueArr.some(el => el.pfizerEpm.replace(/ /g, '').toLowerCase() === value.pfizerEpm.replace(/ /g, '').toLowerCase());
          if (!found) uniqueArr.push(value);
          return ;
        });
      }
      return uniqueArr;
    });
  
    function submitSearch() {
      handleSearch('search', search.value);
    }

    function handlePrev() {
      fetch(pagination.value.links.prev.replace('/api', ''));
    }

    function handleNext() {
      fetch(pagination.value.links.next.replace('/api', ''));
    }

    function setOpen(ukzNumber) {
      data.value.forEach((e) => {
        if (e.ukzNumber == ukzNumber){
          seletedUkz.value = e;
          return (e.open = !e.open);
        } 
        else{
          return (e.open = false);
        }
        
      });
    }

    function handleUpdate() {
      fetch();
      fetchAll();
      updateChangeOrder.value = true;
    }

    return {
      data,
      allData,
      pagination,
      setOpen,
      submitSearch,
      handlePrev,
      handleNext,
      handleFilter,
      search,
      seletedUkz,
      handleUpdate,
      updateChangeOrder,
      getUniquePfizerEpm
    };
  },
};
</script>
