<template>
  <Table
    v-if="data.length"
    class="table"
    :headers="headers"
    :columns="columns"
    :data="data"
    :headerClasses="'font-poppings font-semibold'"
    :columnClasses="'py-2'"
  />
  <div v-else class="text-center font-poppings font-semibold">
    There are no change orders for this job.
  </div>
  <div class="text-right mt-6 space-x-4" v-if="user && user.roleId != userRoleID.CLIENT">
    <Button class="w-48" @click="handleOpenChangeOrderModal" 
      >Add Change Order <plus-icon class="inline-block w-3 h-3 -mt-1"
    /></Button>
  </div>
</template>

<script>
import { ref } from 'vue';
import Table from '@/components/tables/base/Table.vue';
import { useModal } from '@/helpers/composables';
import constant from '@/helpers/constant';
import { useStore } from 'vuex';

export default {
  components: {
    Table,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { open: openChangeOrderModal } = useModal('change-order');
    const store = useStore();
    const { user } = store.state.user;
    const userRoleID = constant.USER_ROLE_ID;
    
    const headers = ref([
      {
        label: 'Change Order No',
        name: 'id',
      },
      {
        label: 'New Scheduled Value',
        name: 'scheduledValue',
      },
      {
        label: 'Date',
        name: 'date',
      },
    ]);

    const columns = ref([
      {
        name: 'id',
        class: 'font-poppins font-semibold',
      },
      {
        name: 'scheduledValue',
      },
      {
        name: 'date',
      },
    ]);

    function handleOpenChangeOrderModal() {
      openChangeOrderModal();
    }

    return {
      headers,
      columns,
      handleOpenChangeOrderModal,
      userRoleID,
      user,
    };
  },
};
</script>
